import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import _ from "lodash";
import { Helmet } from "react-helmet";
import ImageGallery from 'react-image-gallery';

import favicon from '../icons/favicon.ico';

import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
    PinterestShareButton,
    PinterestIcon,
    ViberShareButton,
    ViberIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share';

export default (props) => {

    const galleryRef = React.createRef();

    const id = _.split(_.split(props.location.search, '=')[1], '&')[0];
    const lang = props.pathContext.language;
    const [images, setImages] = useState([]);
    useEffect(() => {
        fetch(`https://nanonet.hr/api/test.php?id=` + id)
            .then(response => response.json())
            .then(resultData => {
                setImages(resultData);
            });
    }, []);

    const [project, setProject] = useState([]);
    useEffect(() => {
        fetch('https://nanonet.hr/api/project.php?id=' + id + '&lang=hr', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        })
            .then(response => response.json())
            .then(resultData => {
                setProject(resultData);
            });
    }, []);

    const url = 'https://nanonet.hr/' + lang + '/project?id=' + project.id;
    const title = project.title;

    const [fullscreenIndex, setFullscreenIndex] = useState(-1);

    return (
        <div className="project-v1">
            <Helmet>
                <meta property="og:image" content="https://nanonet.hr/cover.jpg"/>
                <meta property="og:image:secure_url" content="https://nanonet.hr/cover.jpg"/>
                <title>{project.title}</title>
                <link rel="icon" href={favicon}/>
            </Helmet>

            <div style={{ display: fullscreenIndex === -1 ? 'none' : 'initial' }}>
                <ImageGallery ref={galleryRef}
                              items={_.map(images, image => ({ original: 'https://nanonet.hr/projects/' + id + '/' + image }))}
                              showThumbnails={false}
                              showPlayButton={false}
                              useBrowserFullscreen={false}
                              onScreenChange={(fs) => {
                                  if (!fs) {
                                      setFullscreenIndex(-1);
                                  }
                              }}/>
            </div>

            <div style={{ display: fullscreenIndex === -1 ? 'initial' : 'none' }}>
                <Header lang={lang}/>

                <div className="project-single">

                    <div className="main-panel">

                        <div className="specification">
                            <div className="text project-title">{project.title}</div>
                        </div>

                        <section id="photos">
                            {_.map(images, (image, index) => (
                                <img alt="" src={'https://nanonet.hr/projects/' + id + '/' + image} onClick={() => {
                                    console.log(galleryRef);
                                    galleryRef.current.toggleFullScreen();
                                    galleryRef.current.slideToIndex(index)
                                    setFullscreenIndex(index);
                                }}/>)
                            )}
                        </section>
                    </div>

                    <div className="share-panel">
                        <FacebookShareButton url={url}>
                            <FacebookIcon round={true} bgStyle={{ fill: 'lightgray' }} iconFillColor={'white'}/>
                        </FacebookShareButton>
                        <LinkedinShareButton url={url}>
                            <LinkedinIcon round={true} bgStyle={{ fill: 'lightgray' }} iconFillColor={'white'}/>
                        </LinkedinShareButton>
                        <WhatsappShareButton url={url} title={title}>
                            <WhatsappIcon round={true} bgStyle={{ fill: 'lightgray' }} iconFillColor={'white'}/>
                        </WhatsappShareButton>
                        <PinterestShareButton url={url} title={title} media={url} description={title}>
                            <PinterestIcon round={true} bgStyle={{ fill: 'lightgray' }} iconFillColor={'white'}/>
                        </PinterestShareButton>
                        <ViberShareButton url={url} title={title}>
                            <ViberIcon round={true} bgStyle={{ fill: 'lightgray' }} iconFillColor={'white'}/>
                        </ViberShareButton>
                        <EmailShareButton url={url} title={title}>
                            <EmailIcon round={true} bgStyle={{ fill: 'lightgray' }} iconFillColor={'white'}/>
                        </EmailShareButton>
                    </div>
                </div>

                <Footer/>
            </div>
        </div>
    );
};
